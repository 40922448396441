<template>
    <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="profile-edit-left">
            <h2><i class="fa fa-user"></i> ACCOUNT OVERVIEW</h2>
            <ImageItem :src="imgsrc + user.profile_img" />
            <h4>{{ user.name.toUpperCase() }}</h4>
            <p v-if="user.role_id == 1">Admin</p>
            <p v-if="user.role_id == 2">Buyer/Seller</p>
            <p v-if="user.role_id == 3">Material Inspector</p>
            <ul class="left-profile-link">
                <li v-if="user.role_id == 2">
                    <router-link :to="{ name: 'about' }"
                        ><i class="fa fa-user"></i>About Myself</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'myproducts' }" v-if="!isMaterialTester"
                        ><i class="fa fa-briefcase"></i>My Posts<span>{{
                            product
                        }}</span></router-link
                    >
                </li>
                <!-- <li>
                    <router-link
                        :to="{ name: 'blogs', query: { myBlogs: true } }"
                        v-if="!isMaterialTester"
                        ><i class="fa fa-briefcase"></i>My Blogs<span>{{
                            blog_count
                        }}</span></router-link
                    >
                </li> -->

                <!-- <li v-if="user.role_id == 2">
                    <router-link :to="{ name: 'favourites' }"
                        ><i class="fa fa-heart"></i>My Favourites<span>0</span></router-link
                    >
                </li> -->

                <li>
                    <router-link :to="{ name: 'reviews' }"
                        ><i class="fa fa-star"></i>My Reviews<span>{{
                            rating_count
                        }}</span></router-link
                    >
                </li>

                <li>
                    <router-link :to="{ name: 'dashboard' }"
                        ><i class="fa fa-cogs"></i>Profile Settings</router-link
                    >
                </li>

                <!--          <li v-if="user.role_id ==3">-->
                <!--        <router-link to="/material_enquire"><i class="fa fa-cogs"></i> My Enquires<span>{{enquire_count}}</span></router-link>-->
                <!--</li>-->

                <li>
                    <router-link :to="{ name: 'enquire' }"
                        ><i class="fa fa-cogs"></i>My Enquiries<span>{{
                            enquire_count
                        }}</span></router-link
                    >
                </li>

                <li v-if="isMaterialTester">
                    <router-link :to="{ name: 'requests' }"
                        ><i class="fa fa-cogs"></i>Client Requests<span>{{
                            request_count
                        }}</span></router-link
                    >
                </li>
                <div v-if="user.plans_id == null">
                    <li v-if="!isMaterialTester">
                        <router-link :to="{ name: 'pricing' }"
                            ><i class="fa fa-cogs"></i>My Plans <br />
                        </router-link>
                    </li>
                </div>

                <div v-for="plan in plans" :key="plan.id">
                    <li v-if="!isMaterialTester">
                        <a href="#"
                            ><i class="fa fa-cogs"></i> My Plans
                            <span v-if="currentPlan" style="color:green;">{{
                                currentPlan.plan.plans_name
                            }}</span>
                            <span v-else> Select Any Plans </span>
                        </a>
                    </li>
                </div>

                <div v-if="!isMaterialTester">
                    <li>
                        <router-link :to="{ name: 'my_jobs' }"
                            ><i class="fa fa-user"></i>My Jobs<span>{{
                                jobs_count
                            }}</span></router-link
                        >
                    </li>
                </div>

                <li>
                    <a href="javascript:void(0)" @click="logout" style="color:red;"
                        ><i class="fa fa-power-off" style="color:red;"></i> Logout</a
                    >
                </li>
            </ul>
            <router-link :to="{ name: 'surplus' }" v-if="!isMaterialTester"
                ><button class="primary-button">Submit New Surplus</button></router-link
            >
            <router-link :to="{ name: 'create-job' }" v-if="!isMaterialTester"
                ><button class="primary-button">Post Job</button></router-link
            >
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { HTTP } from "../_helper/http-constants"
import User from "../apis/User"
import { mapState, mapActions, mapGetters } from "vuex"
import request from "../apis/request"
import ImageItem from "./utils/ImageItem"
//axios.defaults.withCredentials = true;

export default {
    name: "dashboard",

    data() {
        return {
            plans: "",
            product: "",
            enquire_count: 0,
            request_count: 0,
            rating_count: 0,
            blog_count: 0,
            ress: {},
            //email: "",
            //mobile: "",
            tagline: "",
            yourself: "",
            expertize: "",
            mobile1: "",
            website: "",
            old: "",
            password: "",
            password_confirmation: "",
            image: "",
            certificates: "",
            jobs_count: "",

            action: "/api/buyerupdate",

            //  imgsrc: 'http://localhost/backend/public/admin_profile/',
            //   imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',

            imgsrc: axios.defaults.uploadURL,
        }
    },
    components: { ImageItem },
    computed: {
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
            isLogged: "authenticated",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
    },
    methods: {
        ...mapActions("auth", {
            logoutUser: "logout",
            fetchUser: "fetchUser",
        }),
        async logout() {
            await this.logoutUser()
            window.location.href = this.$router.resolve({ name: "login" }).href
        },

        previewFiles(event) {
            console.log(event)
            console.log(event.target.files[0])

            this.image = event.target.files[0]
            // console.log(this.image)
        },

        previewFiless(event) {
            console.log(event)
            //console.log(event.target.files[0]);

            this.certificates = event.target.files[0]
        },

        update: function(e) {
            e.preventDefault()

            this.ress = {}

            const form = new FormData()
            form.append("tagline", this.tagline)
            form.append("yourself", this.yourself)
            form.append("expertize", this.expertize)
            form.append("mobile1", this.mobile1)
            form.append("website", this.website)
            // form.append('old',this.old);
            form.append("password", this.password)
            form.append("password_confirmation", this.password_confirmation)
            form.append("image", this.image)
            form.append("certificates", this.certificates)

            //console.log(this.image);

            axios
                .post(this.action, form)
                .then(async (res) => {
                    // window.localStorage.setItem('isLogged', true);
                    // window.localStorage.setItem('user', JSON.stringify(res.data));
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    //  this.$router.push({name: 'dashboard'});
                    //  console.log("ok")
                    await this.fetchUser()
                    this.$toasted.success("Profile Updated in successfully", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })

                    //   alert('Profile Update successfully!');
                })
                .catch((res) => {
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile1: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile1.length; i++) {
                if (!(this.mobile1[i] >= "0" && this.mobile1[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },
    },

    mounted() {
        request.get("/product-count").then((res) => {
            this.product = res.data.product_count
            this.enquire_count = res.data.enquire_count
            this.jobs_count = res.data.jobs_count
            this.request_count = res.data.request_count
            this.rating_count = res.data.rating_count
            this.blog_count = res.data.blog_count
            console.log("PRODUCT COUNT", res)
        })

        request.get("/selectlans").then((res) => {
            this.plans = res.data
        })
    },
}
</script>

<style>
.submit_button {
    padding: 12px 35px;
    width: auto;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #8c0526;
    text-align: center;
    margin: 30px auto;
    display: block;
}
/*body{
  background-color:#f5f5f5;
}*/
.imagePreview {
    width: 100%;
    height: 180px;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
/*.btn-primary
{
  display:block;
  border-radius:0px;
  box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);
  margin-top:-5px;
}*/
.imgUp {
    margin-bottom: 15px;
}
/*.del
{
  position:absolute;
  top:0px;
  right:15px;
  width:30px;
  height:30px;
  text-align:center;
  line-height:30px;
  background-color:rgba(255,255,255,0.6);
  cursor:pointer;
}*/
/*.imgAdd
{
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:#4bd7ef;
  color:#fff;
  box-shadow:0px 0px 2px 1px rgba(0,0,0,0.2);
  text-align:center;
  line-height:30px;
  margin-top:0px;
  cursor:pointer;
  font-size:15px;
}*/
</style>
